<template>
	<v-container>
		<Loading v-if="!loaded" />
		<v-stepper v-model="step" vertical v-show="loaded && !success">
			<div v-for="(test, index) in tests" :key="index">
				<v-stepper-step :complete="step > index" :step="index + 1">
					{{test.title}}
					<small>{{test.subtitle}}</small>
				</v-stepper-step>
				<component v-bind:is="test.component" @done="next" :ref="test.ref" :step="index + 1"></component>
			</div>
		</v-stepper>
		<v-container v-if="success">
			<v-card>
				<v-card-title>Congratulations!</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col class="text-body-1">
								Your browser meets all the requirements for using K4 web products. 
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								If you are still experiencing difficulties, please contact <a href="mailto:support@k4connect.com">support@k4connect.com</a> or call 1-888-472-8568
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
			</v-card>
		</v-container>
	</v-container>
</template>

<script>

import Browser from '@/components/Browser.vue';
import Features from '@/components/Features.vue';
import Login from '@/components/Login.vue';
import Reachability from '@/components/Reachability.vue';
import Loading from '@/components/Loading.vue'

export default {
	name: "Main",

	components: {
		Browser,
		Features,
		Login,
		Reachability,
		Loading
	},

	async mounted()
	{
		
		await this.$requirements.load();
		
		this.loaded = true;

		//load the first step
		this.next();
	},

	methods: {
		next()
		{
			//increment the step
			this.step++;

			this.resetAll();

			if(this.step < this.tests.length + 1)
			{
				this.run();
			} else {
				this.success = true;
				this.$confetti.start(this.confetti);
			}
			
		},

		resetAll()
		{
			this._.forEach(this.tests, (test)=>{
				let component = this.$refs[test.ref][0];
				component.resetBase();
			});
		},

		run()
		{
			//find the current component
			let ref = this.tests[this.step - 1].ref;
			let component = this.$refs[ref][0];

			//run the current test
			component.runBase();
		}
	},

	data: () => ({
		step: 0,
		tests: [
			{
				ref: 'browser',
				title: 'Browser Version',
				subtitle: 'Let\'s check and make sure it meets our requirements...',
				component: Browser
			},
			{
				ref: 'features',
				title: 'Browser Features',
				subtitle: 'Let\'s see if everything works in your browser...',
				component: Features
			},
			{
				ref: 'login',
				title: 'Login',
				subtitle: 'Let\'s check your K4 account...',
				component: Login
			},
			{
				ref: 'reachability',
				title: 'Reachability',
				subtitle: 'Let\'s make sure we can reach K4...',
				component: Reachability
			}
		],
		loaded: false,
		success: false,
		confetti: {
			defaultSize: 7,
			dropRate: 3,
			particlesPerFrame: .75
		}

	}),
};
</script>
