import Vue from 'vue';

class Requirements {
	async load() {
		
		console.log(process.env.VUE_APP_REQUIREMENTS_URL);
		
		const reply = await fetch(process.env.VUE_APP_REQUIREMENTS_URL);
		const requirements = await reply.json();

		Object.assign(this, requirements);
	}
}

export default {

	install() {
		Vue.prototype.$requirements = new Requirements();
	}


}