<template>
	<BaseTest :active="visible" :passed="count">
		{{text}}
		<div v-html="error" class="mt-5 text-caption" v-if="error" />
	</BaseTest>
</template>

<script>

import BaseTest from '@/components/BaseTest.vue';
import Mustache from 'mustache';
import jwt_decode from "jwt-decode";

export default {
	name: "Reachability",

	extends: BaseTest,
	
	components: {
		BaseTest
	},

	methods: {
		reset()
		{

		},

		async run()
		{

			let {reachability} = this.$requirements;

			let errors = [];

			for(let i=0; i < reachability.length; i++)
			{
				let endpoint = reachability[i];

				this.replace(endpoint);

				try {

					await fetch(endpoint.url, endpoint.options);
					
				} catch (e) {
					console.log(e);
					errors.push(`Could not reach ${endpoint.name}`);
				}

			}

			if(errors.length > 0)
			{
				this.count = 0;
				this.error = errors.join('<br>');
				this.text = 'There seems to be a problem reaching parts of K4'
			} else {
				this.count = reachability.length;
				this.text = 'Connected to all parts of K4!'
			}
			
		},

		replace(endpoint)
		{

			let token = this.$cookies.get('jwt');
			let payload = jwt_decode(token);

			let values = {
				jwt: token,
				communityId: payload.communities[0],
				env: process.env.VUE_APP_CLOUD_SUFFIX || ''
			}

			endpoint.url = Mustache.render(endpoint.url, values);

			let options = endpoint.options;

			if(options && options.headers)
			{
				this._.forEach(options.headers, (header, index)=>{

					options.headers[index] = Mustache.render(header, values);

				});
			}
			
		}
	},

	data: () => ({
		text: null		
	}),
};
</script>
