<template>
	<BaseTest :active="visible" :passed="count">
		{{text}}
		<div v-html="error" class="mt-5 text-caption" v-if="error" />
		<v-form v-if="showLogin" v-model="valid" ref="form">
			<div class="text-body-1">Let's try to login...</div>
			<v-text-field class="mt-5" v-model="email" required label="email" outlined :rules="emailRules"></v-text-field>
			<v-text-field v-model="password" required label="password" outlined type="password" :rules="passwordRules"></v-text-field>
			<v-btn @click="login" color="primary" :disabled="!valid">Login</v-btn>
			<div class="text-caption red--text mt-3">{{loginError}}</div>
		</v-form>
	</BaseTest>
</template>

<script>

import BaseTest from '@/components/BaseTest.vue';
import jwt_decode from "jwt-decode";

export default {
	name: "Login",

	extends: BaseTest,
	
	components: {
		BaseTest
	},

	methods: {
		reset()
		{

		},

		async run()
		{

			let token = this.$cookies.get('jwt');

			if(token)
			{
				this.token = token;
				this.decode();
				
			} else {
				this.showLogin = true;
			}
			
			
		},

		decode()
		{
			this.payload = jwt_decode(this.token);
			this.text = `${this.payload.username} is logged in correctly`;
			this.count = 1;
		},

		async login()
		{

			if(!this.$refs.form.validate())
			{
				return;
			}

			this.valid = false;

			try {

				//try auth first

				let reply = await fetch(process.env.VUE_APP_AUTH_URL, {
					method: "post",
					headers: {
						"Authorization": `Basic ${btoa(this.email + ':' + this.password)}`
					}
				});

				let token = await reply.text();

				if(token == 'Access Denied')
				{
					throw new Error(token)

				} else {
					
					//now login to sso (to get session cookie)

					reply = await fetch(process.env.VUE_APP_SSO_URL, {
						method: "post",
						headers: {
							'Content-Type': 'application/json'
						},
						credentials: 'include',
						body: JSON.stringify({
							email: this.email,
							password: this.password
						})
					});

					let account = await reply.json();

					if(!account || !account.guid)
					{
						throw new Error('sso sign in fail');
					}

					this.showLogin = false;
					this.token = token;
					this.decode();

					this.$cookies.set('jwt', token, '1d', '/', process.env.VUE_APP_COOKIE_DOMAIN);

				}
			} catch(e) {
				console.error(e);
				this.loginError = 'Incorrect Login';
				this.$refs.form.reset();
			}
			
		}
	},

	data: () => ({
		valid: false,
		text: null,
		token: null,
		payload: null,
		session: null,
		showLogin: false,
		email: null,
		emailRules: [
			v => !!v || 'E-mail is required',
			v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
		],
		passwordRules: [
			v => !!v || 'Password is required',
		],
		password: null,
		loginError: null
	}),
};
</script>
