<template>
	<v-stepper-content :step="_step" v-show="_visible">
		<v-card color="grey lighten-5" class="mb-12 pa-5">
			<v-row align="center" justify="center">
				<v-col class="col-12 col-sm-6">
					<Status :count="_count" />
				</v-col>
				<v-col class="col-12 col-sm-6">
					<slot />
				</v-col>
			</v-row>
		</v-card>
		<v-btn @click="complete" color="primary" :disabled="!_count">
			Continue
		</v-btn>
	</v-stepper-content>
</template>

<script>

import Status from '@/components/Status.vue';


export default {
	name: "BaseTest",

	components: {
		Status
	},

	computed: {
		//need helpers to get parent values for this component due to parent extending this class
		_step()
		{
			return this.$parent.step;
		},
		_visible()
		{
			return this.$parent.visible;
		},
		_count()
		{
			return this.$parent.count;
		}
	},

	props: [ 'step' ],

	methods: {
		complete()
		{
			this.$parent.$emit('done');
		},

		resetBase()
		{
			this.visible = false;
			this.count = null;
			this.error = null;
			this.reset();
		},

		async runBase()
		{
			this.visible = true;
			await this.run();
		}
	},

	data: () => ({
		visible: false,
		count: null,
		error: null
	}),
};
</script>
