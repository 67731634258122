<template>
	<BaseTest :active="visible" :passed="count">
		{{text}}
		<div v-html="error" class="mt-5 text-caption" v-if="error" />
	</BaseTest>
</template>

<script>

import BaseTest from '@/components/BaseTest.vue';
import Modernizr from 'modernizr';

export default {
	name: "Features",

	extends: BaseTest,
	
	components: {
		BaseTest
	},

	methods: {
		reset()
		{

		},

		async run()
		{
			let {features} = this.$requirements;

			let errors = [];

			for(let i=0; i < features.length; i++)
			{
				let feature = features[i];

				if(!Modernizr[feature.key])
				{
					errors.push(`Browser does not support ${feature.label}`);
				}

			}

			if(errors.length > 0)
			{
				this.count = 0;
				this.error = errors.join('<br>');
				this.text = 'Your browser doesn\'t support a required feature.'
			} else {
				this.count = features.length;
				this.text = 'Your browser supports everything we need!'
			}
		}
	},

	data: () => ({
		text: null
	}),
};
</script>
