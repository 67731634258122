<template>
	<div v-if="count !== null">
		<div v-if="count">
			<v-btn class="ma-6" fab dark large color="green">
				<v-icon dark>fas fa-thumbs-up</v-icon>
			</v-btn>
			{{count}} {{word}} passed!
		</div>
		<div v-if="!count">
			<v-btn class="ma-6" fab dark large color="red">
				<v-icon dark>fas fa-thumbs-down</v-icon>
			</v-btn>
			Sorry, one of the tests failed!
		</div>
	</div>
</template>

<script>
export default {
	name: "Status",

	computed: {
		word()
		{
			return this.count > 1 ? 'tests' : 'test';
		}
	},

	props: [ 'count' , 'active'],

	data: () => ({}),
};
</script>