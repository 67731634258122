<template>
	<BaseTest :active="visible" :passed="count">
		<div v-if="browser && os">
			Your browser is {{browser.name}} version {{browser.version}} running on {{os.name}} {{os.versionName}}
		</div>
		<div class="mt-5 text-caption" v-if="error">
			{{error}}
		</div>
	</BaseTest>
</template>

<script>

import BaseTest from '@/components/BaseTest.vue';
import Bowser from "bowser";

export default {
	name: "Browser",

	extends: BaseTest,
	
	components: {
		BaseTest
	},

	methods: {
		reset()
		{

		},

		async run()
		{
			const info = Bowser.getParser(window.navigator.userAgent);

			this.browser = info.getBrowser();
			this.os = info.getOS();

			let success = info.satisfies(this.$requirements.browser);

			if(success)
			{
				this.count = 1;
			} else {
				this.count = 0;
				this.error = 'Your browser doesnt meet our minimum requirements.';
			}
			
		}
	},

	data: () => ({
		browser: null,
		os: null
	}),
};
</script>
