<template>
	<v-app>
		<v-app-bar app>
			<v-img
				class="mx-2"
				max-width="100"
				max-height="40"
				contain
				:src="require('@/assets/logo.png')"
			></v-img>
			<v-spacer />
			<v-btn @click="reload" icon>
				<v-icon>fas fa-redo</v-icon>
			</v-btn>
		</v-app-bar>
		<v-main>
			<Main />
		</v-main>
	</v-app>
</template>

<script>
import Main from "./components/Main";

export default {
	name: "App",

	components: {
		Main,
	},

	methods: {
		reload() {
			location.reload();
		},
	},

	data: () => ({
		//
	}),
};
</script>
