import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import Requirements from './plugins/requirements'
import VueConfetti from 'vue-confetti'
 
 
Vue.use(VueLodash, { lodash: lodash })
Vue.use(Requirements);
Vue.use(require('vue-cookies'));
Vue.use(VueConfetti)

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
